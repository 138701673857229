import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ClientLogo from "../components/clientsLogo/clientsLogo";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";


export const AboutWrapper=styled.div`
    max-width: 960px;
    margin:15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    box-shadow: 0px 5px 10px #ccc;
    min-height: 550px;
    padding: 15px;
    .row {
      margin-right: -15px;
      margin-left: -15px;
  }
  h2 {
    color: #2e308f;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 30px;
  }
  .text-justify {
    text-align: justify;
}
h4{
  margin-top: 10px;
  margin-bottom: 10px;
  font-size:18px;
}
p {
  margin: 0 0 10px;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiAboutSection {
        nodes {
          description
          hero_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          sub_title
          title
          introduction
        }
      }
    }
  `)
  const node  = data.allStrapiAboutSection.nodes[0];
   return (
    <AboutWrapper>
      <Header />
      <div class="inner-slider">
        <img src={node.hero_image.childImageSharp.fluid.src} alt="" title="" class="img-responsive"></img>
      </div>
      <div class="row">
        <h2 class="col-md-12">{node.title}</h2>
        <div class="col-md-12 text-justify">
          <p class="about-home" style={{color:`#2e308f`}} dangerouslySetInnerHTML={{ __html: node.description }}></p>        
          <hr />
          <h4><b>{node.sub_title}</b></h4>
          <p dangerouslySetInnerHTML={{ __html: node.introduction }}></p>
          
        </div>
      </div>
      <ClientLogo />
      <Footer />
    </AboutWrapper>
  )
}

export default IndexPage;
